import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import {
  MAX_BODY_TEXT_LENGTH,
  MAX_HEADER_TEXT_LENGTH,
  LINK_REGEXP,
  APP_VERSION_REGEXP,
  EMAIL_REGEXP,
} from 'const/adPackage'
import { CPA_PERCENT_FOR_CB, CPA_PERCENT_FOR_PLUS_CB } from 'const/retailers'
import { MIN_PASSWORD_LENGTH } from 'const/admins'
import {
  getRequiredFieldError,
  getCBWithCPAError,
  getMaxLengthError,
  getMinLengthError,
  REQUIRED,
  ZERO_OR_NATURAL,
  INVALID_TEXT,
  INVALID_LINK,
  INVALID_VERSION,
  INVALID_EMAIL,
  REQUIRED_HEADER_IMAGE,
  REQUIRED_KG,
  INVALID_MOBILE_LINK,
  ILLEGAL_CHARACTERS,
  INVALID_EXCLUSIVE_PRIORITY,
  INVALID_PERCENTAGE,
  INVALID_PERCENTAGE_SUM,
  VALID_NUMBER,
} from 'const/errors'
import { FIELD_NAMES } from 'const/forms'
import { ENTITY_ATTRS_SUBFIELDS } from 'const/api'
import { FILENAME_REGEXP } from 'const/landingPage'

const composeValidators =
  (...validators) =>
  (value, allValidators) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValidators),
      undefined
    )

const dependent = (validatorA, validatorB) => (value, allValues) => {
  const errorA = validatorA(value, allValues)
  const errorB = validatorB(value, allValues)

  if (!errorA && errorB) {
    return errorB
  }

  if (!errorB && errorA) {
    return errorA
  }
}

const conditionValidators = (validatorA, validatorB) => (value, allValues) => {
  const errorA = validatorA(value, allValues)
  const errorB = validatorB(value, allValues)

  if (!errorA) {
    return
  }

  return errorB
}

export const requiredNested = (value) => {
  if (isEmpty(value)) {
    return REQUIRED
  }
}

export const maxLength = (max) => (value) => {
  if (value?.length > max) {
    return getMaxLengthError(max)
  }
}

export const isValidExclusivePriority = (value) => {
  if (value > 50 || value < 1) {
    return INVALID_EXCLUSIVE_PRIORITY
  }
}

export const minLength = (max) => (value) => {
  if (value?.length < max) {
    return getMinLengthError(max)
  }
}

export const required = (value) => {
  if (!value) {
    return REQUIRED
  }
}

export const requiredText = (value) => {
  if (!value?.trim()) {
    return INVALID_TEXT
  }
}

export const optionalText = (value) => {
  if (value && !value.trim()) {
    return INVALID_TEXT
  }
}

export const isValidLink = (value) => {
  if (!LINK_REGEXP.test(value)) {
    return INVALID_LINK
  }
}

export const requiredNumber = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return VALID_NUMBER
  }
}

export const isValidAppVersion = (value) => {
  if (!APP_VERSION_REGEXP.test(value)) {
    return INVALID_VERSION
  }
}

export const isValidFilename = (value) => {
  if (value.match(FILENAME_REGEXP)) {
    return ILLEGAL_CHARACTERS
  }
}

export const isValidEmail = (value) => {
  if (!EMAIL_REGEXP.test(value)) {
    return INVALID_EMAIL
  }
}

export const isValidEmailNotRequired = (value) => {
  if (!value) return
  if (!EMAIL_REGEXP.test(value)) {
    return INVALID_EMAIL
  }
}

export const isValidFAQLink = (value) => {
  if (!LINK_REGEXP.test(value.link)) {
    return INVALID_LINK
  }
}

export const isValidMobileLink = (value) => {
  if (!LINK_REGEXP.test(value[ENTITY_ATTRS_SUBFIELDS.mobileLink])) {
    return INVALID_MOBILE_LINK
  }
}

export const isValidUrl = (value) => {
  if (!LINK_REGEXP.test(value[FIELD_NAMES.url])) {
    return INVALID_LINK
  }
}

export const areValidDomains = (value) => {
  if (!value || value.length === 0) {
    return undefined
  }

  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const domains = value.split(',').map((domain) => domain.trim())

  const isValid = domains.every((domain) => domainRegex.test(domain))

  return isValid ? undefined : 'Please enter valid domains separated by commas'
}

export const bodyLength = composeValidators(
  optionalText,
  maxLength(MAX_BODY_TEXT_LENGTH)
)
export const passwordLength = composeValidators(
  required,
  minLength(MIN_PASSWORD_LENGTH)
)
export const nameValidator = composeValidators(required, requiredText)
export const isValuePresent = (value) => !!value
export const headerLength = conditionValidators(
  isValuePresent,
  composeValidators(requiredText, maxLength(MAX_HEADER_TEXT_LENGTH))
)
export const requireImageFromSet =
  (secondImageFieldName) => (value, allValues) => {
    if (!value && !allValues?.[secondImageFieldName]) {
      return REQUIRED
    }
  }

export const requiredIfAnotherNotPresented =
  (another) => (value, allValues) => {
    if (!value && !allValues?.[another]) {
      return REQUIRED
    }
  }

export const requiredCouponsRule = requiredIfAnotherNotPresented(
  FIELD_NAMES.mainRetailerId
)
export const requiredCouponsRetailer = requiredIfAnotherNotPresented(
  FIELD_NAMES.ruleId
)

const bannerIsModified = (allValues) =>
  allValues?.[FIELD_NAMES.bannerLink] ||
  allValues?.[FIELD_NAMES.bannerImage] ||
  allValues?.[FIELD_NAMES.bannerText]

const bannerIsFilledIn = (allValues) =>
  allValues?.[FIELD_NAMES.bannerLink] && allValues?.[FIELD_NAMES.bannerImage]

const isTextAd = (allValues) => allValues?.[FIELD_NAMES.textOnly]

const salesAreModified = (allValues) =>
  allValues?.[FIELD_NAMES.salesWeb1] ||
  allValues?.[FIELD_NAMES.salesWeb2] ||
  allValues?.[FIELD_NAMES.salesMobile1] ||
  allValues?.[FIELD_NAMES.salesMobile2] ||
  allValues?.[FIELD_NAMES.salesMobileSeeAll1] ||
  allValues?.[FIELD_NAMES.salesMobileSeeAll2]

const salesAreFilledIn = (allValues) =>
  (allValues?.[FIELD_NAMES.salesWeb1] || allValues?.[FIELD_NAMES.salesWeb2]) &&
  (allValues?.[FIELD_NAMES.salesMobile1] ||
    allValues?.[FIELD_NAMES.salesMobile2]) &&
  (allValues?.[FIELD_NAMES.salesMobileSeeAll1] ||
    allValues?.[FIELD_NAMES.salesMobileSeeAll2])

const isExclusiveCoupon = (value, allValues) =>
  allValues?.[FIELD_NAMES.exclusive]

export const requiredSalesImage =
  (secondImageFieldName) => (value, allValues) => {
    if (
      isTextAd(allValues) ||
      (bannerIsFilledIn(allValues) && !salesAreModified(allValues))
    )
      return
    if (!allValues?.[secondImageFieldName] && !value) {
      return REQUIRED
    }
  }

export const requireBannerField = (value, allValues) => {
  if (
    isTextAd(allValues) ||
    (salesAreFilledIn(allValues) && !bannerIsModified(allValues))
  )
    return
  if (!value) {
    return REQUIRED
  }
}

export const isNotDisabled = (setKey) => (value, allValues) =>
  !get(allValues, `${setKey}[${FIELD_NAMES.deleted}]`) &&
  !get(allValues, `${setKey}[${FIELD_NAMES.isLocked}]`)

export const validCBWithCPA = (cpa, fieldsKey) => (value, allValues) => {
  if (!allValues?.[fieldsKey]?.[FIELD_NAMES.cpa]) return
  const cbValue = isObject(value) ? value?.cb : value
  const maxCBValue = cpa * allValues[fieldsKey][FIELD_NAMES.cpa]
  if (cbValue > maxCBValue) {
    return getCBWithCPAError(maxCBValue)
  }
}

export const validateHeaderWeb = (fieldKey) => (value) => {
  if (fieldKey === FIELD_NAMES.headerWeb) {
    return required(value)
  }
}

export const validBannerLink = conditionValidators(
  composeValidators(requireBannerField, isValuePresent),
  isValidLink
)

export const requiredContact = (value) => {
  if (!value?.contact || !value?.name) {
    return REQUIRED
  }
}

export const zeroOrNatural = (value) => {
  if (value === null || value < 0) {
    return ZERO_OR_NATURAL
  }
}

const requiredField = (field) => (value) => {
  if (!value[field]) {
    return getRequiredFieldError(field)
  }
}

export const validFilename = composeValidators(required, isValidFilename)
export const validUserEmail = composeValidators(required, isValidEmail)
export const validProductLink = dependent(required, isValidLink)
export const validAppVersion = composeValidators(required, isValidAppVersion)
export const validLink = composeValidators(required, isValidLink)
export const validHeaderWeb = (setKey, fieldKey) =>
  conditionValidators(isNotDisabled(setKey), validateHeaderWeb(fieldKey))
export const validCBs = (fieldsKey) =>
  dependent(zeroOrNatural, validCBWithCPA(CPA_PERCENT_FOR_CB, fieldsKey))
export const validPlusCBs = (fieldsKey) =>
  dependent(zeroOrNatural, validCBWithCPA(CPA_PERCENT_FOR_PLUS_CB, fieldsKey))
export const validFAQLink = composeValidators(
  requiredField('link'),
  isValidFAQLink
)

export const isValidTrafficPercentage = (value, allValues) => {
  const sumOfPercentages = allValues[FIELD_NAMES.marketingPagesUrls].reduce(
    (result, item) => result + ~~item[FIELD_NAMES.trafficPercentage],
    0
  )

  if (
    ~~value[FIELD_NAMES.trafficPercentage] < 0 ||
    ~~value[FIELD_NAMES.trafficPercentage] > 100
  ) {
    return INVALID_PERCENTAGE
  }

  if (sumOfPercentages !== 100) {
    return INVALID_PERCENTAGE_SUM
  }
}

export const validMarketingPageUrl = composeValidators(
  requiredField(FIELD_NAMES.url),
  isValidUrl,
  isValidTrafficPercentage
)
export const validFAQMobileLink = dependent(
  requiredField('mobile_link'),
  isValidMobileLink
)
export const requiredMaxLength = (max) =>
  composeValidators(maxLength(max), required)

const requiredStartDate = (value) => {
  if (requiredField('dates')(value) || !value.dates?.[0]) {
    return getRequiredFieldError('start date')
  }
}

export const promoPeriodCashback = (fieldsKey) =>
  composeValidators(
    required,
    dependent(requiredField('cb'), requiredStartDate),
    dependent(
      requiredField('dates'),
      validCBWithCPA(CPA_PERCENT_FOR_PLUS_CB, fieldsKey)
    )
  )
export const promoPlusPeriodCashback = (fieldsKey) =>
  composeValidators(
    required,
    dependent(requiredField('cb'), requiredStartDate),
    dependent(
      requiredField('dates'),
      validCBWithCPA(CPA_PERCENT_FOR_PLUS_CB, fieldsKey)
    )
  )
export const faqValidator = composeValidators(
  requiredField('question'),
  requiredField('answer'),
  validFAQLink,
  validFAQMobileLink
)

export const commentValidator = (value) => {
  if (value) {
    if (!value.trim()) {
      return REQUIRED
    }
  }
}

export const requiredHeaderImage = (data) => {
  if (!data[FIELD_NAMES.hasHeaderWebImage]) {
    return REQUIRED_HEADER_IMAGE
  }
}

const requiredKarmaGives = (data) => {
  if (!data[FIELD_NAMES.stake]) {
    return REQUIRED_KG
  }
}

export const requiredKarmaGivesAndImages = composeValidators(
  requiredKarmaGives,
  requiredHeaderImage
)

export const validExclusivePriority = conditionValidators(
  isExclusiveCoupon,
  isValidExclusivePriority
)
